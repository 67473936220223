
<style scoped>
  .f-w-600{font-weight:600}
  .f-w-900{font-weight:900}
  small{font-size:10px!important;color:#2ea6f5 !important}
  .basket-container{float:left;width:100%;height:100%}
  .basket-container .b-header-img{cursor:pointer}
  .basket-container .b-header-img img{width:24px;}
  .basket-container .b-header{padding: 10px;}
  .basket-container .b-title{font-weight:600}
  .show-basket .flex-table-container{flex-basis: Calc(100% - 350px);max-width: Calc(100% - 350px);}
  /* .basket-container{flex-basis: 0px;display:none} */
  /* .show-basket .basket-container{flex-basis: 350px;max-width: 350px;} */
  .basket-container .v-card{height:100%}
  .buy-sell-area{height: 40px;width: 66px;left: 0;top: 0;z-index: 9;padding-top: 7px;}
  .basket-container .buy-sell-area{width: auto;height: auto;padding: 0;}
  .basket-container .buy-sell-area .btn{margin: 0px 2px;font-size: 14px;padding: 2px 7px;border-radius: 3px;width: 20px;height: 20px;letter-spacing: 0px;color: #333;cursor:pointer;opacity:0.9;background: #ccc;}
  .buy-sell-area .btn:hover{opacity:1}
  .buy-sell-area .btn.buy{background: #00B894;color:#fff}
  .buy-sell-area .btn.sell{background: #f44336;color:#fff}
  .basket-list-group{overflow-y:auto;height:calc(100% - 100px);border-bottom: 1px solid rgb(137 137 137 / 52%);}
  .basket-list{border-bottom:1px solid rgb(137 137 137 / 12%);font-weight:600;float:left;width:100%;padding:10px;transition: all .2s;}
  .basket-list:hover{background: rgb(217 217 217 / 30%);}
  .theme--dark .basket-list{border-bottom:1px solid rgb(137 137 137 / 12%);}
  .theme--dark .basket-list:hover{background: rgb(217 217 217 / 12%);}
  .basket-list .float-left{width:20px;}
  .basket-list .float-left img{cursor:pointer;width: 16px;}
  .basket-list .float-middle{padding: 0 10px;width:calc(100% - 100px);float:left}
  .select-option{float:left;width:100%;position:relative}
  .select-option svg{position:absolute;right:0px;top: 3px;height: 14px;width: 14px;}
  .basket-list .float-middle .box{border: 1px solid rgb(137 137 137 / 52%);border-radius: 3px;float:left;font-size:12px;margin-right:10px;}
  .basket-list .float-middle .b-title{font-size:12px;margin-bottom: 4px;}
  .basket-list .float-middle .unit-select{float: left;width: 100%;}
  .basket-list .float-middle .unit-select span{padding: 2px 0px;float:left;width:45px;text-align:center}
  .basket-list .float-middle select{z-index: 1;position:relative;padding: 2px 16px 2px 8px;width:100% ;float: left;}
  .theme--dark .basket-list .float-middle select{color:#fff}
  .theme--dark .basket-list .float-middle select option{background:#333}
  .basket-list .float-middle .unit-select .select-option{width:calc(100% - 45px);}
  .basket-list .float-right{font-size: 12px;text-align: right;font-weight:600;width:80px;}
  .basket-list .float-right .buy-sell-area{margin-top:5px;}
  .basket-container .v-card__actions .float-left{line-height: 1!important;}
  .theme--dark *{font-weight:300!important}
</style>

<template>
<div>
          <div class="basket-container">
            <!-- <div class="basket-toggle-btn" @click="buySellArea.showBasket = false">C</div> -->
      
            <v-card class="mx-auto">
              <div class="w-100 text-center b-header">
              <span class="float-left b-header-img" @click.stop="basketGval.dialog = true"  v-if="basketGval.basketList.length>0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <img class="" src="@/assets/images/optionchain/clearall.svg" alt="Clear all" v-bind="attrs" v-on="on" 
                   />
                  </template>
                    <span>Remove all</span>
                </v-tooltip>
                </span>
              <span class="b-title">Basket Order</span>
              <span class="float-right b-header-img">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                     <!-- @click="basketGval.buySellArea.showBasket = false" -->
                    <img class="" src="@/assets/images/optionchain/back.svg" alt=""  @click="basketGval.showBasket = false" v-bind="attrs" v-on="on"/>
                  </template>
                    <span>Back</span>
                </v-tooltip>
              </span>
              </div>
              <v-divider></v-divider>
              <div class="basket-list-group">
                  <div class="body-2 text-center pa-10" v-if="basketGval.basketList.length==0">No order in you basket.</div>
                  <div v-for="item in basketGval.basketList" :key="item.day" class="basket-list">
                    <div class="w-100">
                      <div class="float-left">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }"><img class="" src="@/assets/images/optionchain/clear.svg" alt="Remove" 
                          v-bind="attrs" v-on="on" @click="_removeOrder(item)"/></template><span>Remove</span>
                        </v-tooltip>
                        </div>
                      <div class="float-middle">
                        <div class="b-title">{{item.stock}}</div>
                        <div class="box">
                        <div class="unit-select">
                          <div class="select-option p-relative">
                            <svg class="float-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" fill="#929292">
                              <path  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                            </svg>
                            <select  v-model="item.limit"><option v-for="(n) in 50" :key="n" :value="n" >{{n}}</option></select></div>
                            <span class="bg-lightgrey">X 50</span>
                        </div>
                        </div><div class="box"><div class="select-option p-relative">
                            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="float-left" fill="#929292">
                              <path  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                            </svg>
                            <select v-model="item.marketType">
                                <option value="MKT" selected>MKT</option>
                                <option value="LMT">LMT</option>
                            </select></div></div>
                      </div>
                      <div class="float-right">
                        <input type="number" class="fw-100 text-right font-clr" v-model="item.value" :disabled="item.marketType == 'MKT'"/>
                        <div class="buy-sell-area fw-100 float-right">
                          <span class="btn" :class="{'buy':item.buySell=='buy'}" @click='_updateOrder(item,"buy")'>B</span>
                          <span class="btn" :class="{'sell':item.buySell=='sell'}" @click='_updateOrder(item,"sell")'>S</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                <v-card-actions class="px-4">
                    <div class="col float-left pa-0">
                      <div class="margin-label f-w-600 caption">Margin Value
                        <small class="">(apprx)</small></div>
                      <div class="margin-value f-w-900">
                        ₹{{comaSeperation(totalMargin.toFixed(2))}}
                      </div>
                    </div>
                    <div class="col float-right text-right pa-0 pb-1">
                      <v-btn depressed x-small outlined class="mr-2" >Analyze</v-btn>
                      <v-btn depressed x-small color="primary" >Trade (6)</v-btn>
                    </div>
                </v-card-actions>
               </v-card>


            <v-dialog v-model="basketGval.dialog"  max-width="290">
              <v-card>
                <v-card-title class="text-h5">
                  Confirmation
                </v-card-title>
                <v-card-text>
                  Are you sure to remove all order list in your basket?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="basketGval.dialog = false;">Dismiss</v-btn>
                  <v-btn color="green darken-1" text @click="basketGval.dialog = false;_removeAllOrder()">Remove all</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </div>
      </div>
</template>

<script>
import { mapState,mapActions , mapGetters  } from 'vuex'
import commonFunctions from "@/mixins/commonFunctions";
export default {
  mixins: [commonFunctions],
  data: () => ({
    }),
    computed: {
    ...mapGetters([
      'totalMargin'
    ]),
      ...mapState(['basketGval']),
      ...mapActions([
        'removeAllOrder'
      ]),
    },
    methods: {
      ...mapActions([
         'removeOrder','updateOrder'
      ]),
      _removeAllOrder(){this.removeAllOrder;},
      _removeOrder(item){this.removeOrder(item)},
      _updateOrder(item,bs){
        this.updateOrder({item:item,bs:bs});
      },
    },
  mounted() {
      console.log(this.totalMargin)
  },
}
</script>